<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <HeaderPanel
          title="Product Collection"
          :filter="filter"
          :hasFilter="false"
          placeholder="Search Name"
          :hasDropdown="false"
          @search="handleSearch"
          routerPath="/product-collection/detail/0"
        ></HeaderPanel>
      </div>

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
          ><template v-slot:cell(collection_code)="{ item }">
            {{ item.collection_code || "-" }}</template
          >
          <template v-slot:cell(detail)="{ item }">
            <div class="d-flex justify-content-center">
              <b-button
                variant="link"
                class="text-warning px-4 py-0"
                @click="openCollectionDetail(item.id)"
              >
                <font-awesome-icon icon="pencil-alt" title="Detail" />
              </b-button>

              <div class="" @click="openModalDelete(item.id)">
                <b-icon size="xl" icon="trash-fill" class="icon-size pointer" />
              </div>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(created_time)="data">
            <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.created_time | moment("HH:mm:ss") }}
            </div>
          </template>
        </b-table>

        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
      <ModalConfirm
        ref="confirmModal"
        :text="confirmMsg"
        @handler="deleteCollection"
      />
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import ModalConfirm from "@/components/modal/ModalConfirm";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: { ModalConfirm, OtherLoading },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        excel_file: {
          required,
        },
      },
    };
  },
  data() {
    return {
      form: {
        email: "",
        file_name: "",
        excel_file: "",
        user_guid: "",
      },
      currnet_collection: "",
      isDisable: false,
      modalImport: false,
      isLoading: false,
      modalShow: false,
      id: this.$route.params.id,
      isBusy: false,
      items: [],
      fields: [
        {
          key: "collection_code",
          label: "Code",
          thClass: "w-2",
        },
        {
          key: "name",
          label: "Name",
          thClass: "w-2",
        },

        {
          key: "total_product",
          label: "Total Product",
          thClass: "w-2",
        },
        {
          key: "description",
          label: "Description",
          thClass: "w-2",
        },
        {
          key: "created_time",
          label: "Created Time",
          thClass: "w-2",
        },
        {
          key: "detail",
          label: "",
          thClass: "w-1",
        },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        product_id: Number(this.$route.params.id),
      },
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      collectionList: [],
      confirmMsg: "",
      deleteId: "",
    };
  },
  async created() {
    await this.getCollection();
  },
  methods: {
    handleSearch() {
      this.filter.page = 1;
      this.getCollection();
    },

    async getCollection() {
      this.isBusy = true;
      const res = await this.axios.post(
        `/productmenu/get_collection_list`,
        this.filter
      );
      this.rows = res.data.detail.count;
      this.items = res.data.detail.data;
      // this.getDropdownCollection();
      this.isBusy = false;
    },
    async getDropdownCollection() {
      const res = await this.axios(`/ProductMenu/get_dropdown_collection_list`);
      this.collectionList = res.data.detail;
    },
    pagination(val) {
      this.filter.page = val;
      this.getCollection();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.getCollection();
    },
    openCollectionDetail(id) {
      this.$router.push(`/product-collection/detail/${id}`);
    },
    openModalDelete(id) {
      this.confirmMsg = "Confirm Deletion?";
      this.$refs.confirmModal.show();
      this.deleteId = id;
    },
    async deleteCollection(isOk) {
      this.isLoading = true;
      if (isOk) {
        const res = await this.axios.delete(
          `/productmenu/delete/collection/${this.deleteId}`
        );
        if (res.data.result == 1) {
          this.successAlert();
          this.getCollection();
        } else {
          this.errorAlert(res.data.message);
        }
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-collection {
  width: 50% !important;
}

.icon-size {
  font-size: 20px;
}
</style>
